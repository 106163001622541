import clsx from 'clsx';
import React from 'react';

export interface ContainerProps {
  className?: string;
  padding?: 'container' | 'xs' | 's' | 'm' | 'l' | 'xl';
  maxWidth?:
    | 'max-w-3xl'
    | 'max-w-4xl'
    | 'max-w-5xl'
    | 'max-w-6xl'
    | 'max-w-7xl';
  children?: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = ({
  padding,
  maxWidth,
  children,
  className,
}) => {
  return (
    <div
      className={clsx(
        'relative mx-auto',
        padding === 'container' && 'px-4 py-8 md:px-8 md:py-12',
        padding === 'xs' && 'py-2',
        padding === 's' && 'py-2 md:py-4',
        padding === 'm' && 'py-3 md:py-6',
        padding === 'l' && 'py-6 md:py-12 lg:py-16',
        padding === 'xl' && 'py-6 md:py-12',
        maxWidth,
        className
      )}>
      {children}
    </div>
  );
};

export default Container;
