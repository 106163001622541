import { Typography } from '@components/Typography';
import { FlipBackward } from '@untitled-ui/icons-react';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

export interface BreadcrumbProps {
  text: string;
  slug: string;
  className?: string;
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  slug,
  text,
  className,
}) => {
  if (!slug) {
    return null;
  }

  return (
    <nav className={clsx('flex items-center space-x-2', className)}>
      <FlipBackward className="size-5 shrink-0" />
      <Typography tag="p" size="text-sm">
        <Link href={slug} className="hover:underline">
          View all {text}
        </Link>
      </Typography>
    </nav>
  );
};
export default Breadcrumb;
