'use client';
import { PageWrapper } from '@components/PageWrapper';
import Link from 'next/link';

function NotFound() {
  return (
    <PageWrapper
      heading="Page not found"
      summary="Sorry, We couldn't find that page">
      <>
        <div className="container mx-auto mt-6 flex flex-1 flex-col">
          <div className="w-full text-center">
            Why not head back to the{' '}
            <Link href="/" className="underline">
              homepage
            </Link>
            , or check out{' '}
            <Link href="/whats-on" className="underline">
              what&apos;s on
            </Link>{' '}
            in the Sunshine Coast
          </div>
        </div>
      </>
    </PageWrapper>
  );
}

export default NotFound;
