import Breadcrumb, { BreadcrumbProps } from '@components/Breadcrumb';
import Container from '@components/Container';
import Favourite from '@components/Favourite';
import { Typography } from '@components/Typography';
import clsx from 'clsx';
import React from 'react';

export interface PageWrapperProps {
  heading?: string;
  summary?: string;
  hero?: React.ReactNode;
  postHero?: React.ReactNode;
  highlightColor?: string;
  breadcrumb?: BreadcrumbProps;
  className?: string;
  showShare?: boolean;
  hideHeading?: boolean;
  productID?: string;
  children?: React.ReactNode;
  centeredIntro?: boolean;
  itemType?: string;
}

// const Share = dynamic<ShareProps>(() => import('@components/Share'), {
//   ssr: false,
// });

export const PageWrapper: React.FC<PageWrapperProps> = ({
  heading,
  summary,
  breadcrumb,
  hero,
  postHero,
  highlightColor = 'bg-brand-sand',
  children,
  showShare,
  hideHeading,
  productID,
  className,
  centeredIntro,
  itemType,
}) => {
  const headerClasses = clsx('space-y-8 pb-4 md:pb-6 lg:pb-8', {
    'text-center': centeredIntro,
  });

  const cleanHeading = heading?.replace(/[^\x00-\x7F]/g, '');

  return (
    <div className="px-4" itemScope itemType={itemType}>
      <div className="relative pt-20 md:pt-24">
        {hero && (
          <div
            className={clsx(
              'full-width absolute bottom-20 left-0 right-0 top-0',
              highlightColor
            )}>
            <div className="pattern-one absolute bottom-0 left-0 right-0" />
          </div>
        )}
        <Container
          maxWidth="max-w-7xl"
          className="flex flex-col-reverse gap-y-4 md:flex-col">
          <div className="flex items-center justify-between">
            {breadcrumb ? <Breadcrumb {...breadcrumb} /> : <div />}
            <div className="flex items-center divide-x divide-gray-300 overflow-hidden rounded-lg bg-white/20">
              {/* {showShare && (
                <Share title={cleanHeading} description={summary} />
              )} */}
              {productID && <Favourite productID={productID} style="badge" />}
            </div>
          </div>
          {hero}
        </Container>
      </div>
      <Container maxWidth="max-w-6xl">
        {postHero}
        {!hideHeading && heading && (
          <div className="pt-4 md:pt-8">
            <div className={headerClasses}>
              <div className="relative space-y-2 md:space-y-8">
                {heading && (
                  <Typography
                    tag="h1"
                    size="text-4xl md:text-5xl lg:text-6xl max-w-[90%]"
                    className="!leading-normal">
                    {cleanHeading}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        )}

        <main
          role="main"
          className={clsx(
            'space-y-2 py-8 md:space-y-8 md:pb-16 md:pt-8',
            className
          )}>
          {summary && (
            <Typography
              tag="p"
              size="text-2xl md:text-3xl"
              className="!leading-normal">
              {summary}
            </Typography>
          )}
          {children}
        </main>
      </Container>
    </div>
  );
};

export default React.memo(PageWrapper);
